const appConfig = {
  // apiPrefix: 'http://b2b.4games.test:7888/api',
  apiPrefix: '/api',
  authenticatedEntryPath: '/home',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  enableMock: false,
};

export default appConfig;
