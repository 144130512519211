import { combineReducers } from 'redux';
import theme from './theme/themeSlice';
import auth from './auth';
import base from './base';
import cart from './cart/cartSlice';

const rootReducer = asyncReducers => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    cart,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
