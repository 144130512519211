import ApiService from "./ApiService";

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: "/login",
        method: "post",
        data,
    });
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: "/logout",
        method: "post",
        data,
    });
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: "/forgot-password",
        method: "post",
        data,
    });
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: "/reset-password",
        method: "post",
        data,
    });
}

export async function apiCheckAuth() {
    return ApiService.fetchData({
        url: "/auth/check",
        method: "post",
    });
}
