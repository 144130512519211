import ApiService from './ApiService';

// Catalog
export async function apiGetCatalogTree(data) {
  return ApiService.fetchData({
    url: '/v1/catalog/tree',
    method: 'get',
    params: data,
  });
}

export async function apiGetProducts(data) {
  return ApiService.fetchData({
    url: '/v1/catalog/products',
    method: 'get',
    params: data,
  });
}

// Shopping Cart
export async function apiGetShoppingCart(data) {
  return ApiService.fetchData({
    url: '/v1/cart',
    method: 'get',
    params: data,
  });
}

export async function apiStoreToShoppingCart(data) {
  return ApiService.fetchData({
    url: '/v1/cart',
    method: 'post',
    data,
  });
}

export async function apiClearShoppingCart(data) {
  return ApiService.fetchData({
    url: '/v1/cart',
    method: 'delete',
    data,
  });
}

export async function apiSendProductFeedback(data) {
  return ApiService.fetchData({
    url: '/v1/feedback/product',
    method: 'post',
    data,
  });
}

// Orders
export async function apiGetOrders(data) {
  return ApiService.fetchData({
    url: '/v1/orders',
    method: 'get',
    params: data,
  });
}

export async function apiCreateOrder(data) {
  return ApiService.fetchData({
    url: '/v1/orders',
    method: 'post',
    data,
  });
}

export async function apiGetOrder(pk) {
  return ApiService.fetchData({
    url: `/v1/orders/${pk}`,
    method: 'get',
  });
}

// Banners
export async function apiGetBanners(data) {
  return ApiService.fetchData({
    url: '/v1/banners',
    method: 'get',
    params: data,
  });
}

export async function apiGetBanner(pk) {
  return ApiService.fetchData({
    url: `/v1/banners/${pk}`,
    method: 'get',
  });
}

// Invoices
export async function apiGetInvoices(data) {
  return ApiService.fetchData({
    url: '/v1/invoices',
    method: 'get',
    params: data,
  });
}
