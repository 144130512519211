export const APP_NAME = 'Elstar';
export const PERSIST_STORE_NAME = 'admin';
export const REDIRECT_URL_KEY = 'redirectUrl';

export const statusColor = {
  0: {
    label: 'Inactive',
    dotClass: 'bg-gray-300',
    textClass: 'text-gray-300',
  },
  1: {
    label: 'Active',
    dotClass: 'bg-emerald-500',
    textClass: 'text-emerald-500',
  },
};

export const orderStatusColor = {
  NEW: {
    label: 'New',
    dotClass: 'bg-amber-500',
    textClass: 'text-amber-500',
    bgClass: 'bg-amber-100',
  },
  PROCESS: {
    label: 'In Process',
    dotClass: 'bg-pink-500',
    textClass: 'text-pink-500',
    bgClass: 'bg-pink-100',
  },
  PAYED: {
    label: 'Paid',
    dotClass: 'bg-emerald-500',
    textClass: 'text-emerald-500',
    bgClass: 'bg-emerald-100',
  },
  SHIPPED: {
    label: 'Shipped',
    dotClass: 'bg-blue-500',
    textClass: 'text-blue-500',
    bgClass: 'bg-blue-100',
  },
  REJECTED: {
    label: 'Rejected',
    dotClass: 'bg-red-500',
    textClass: 'text-red-500',
    bgClass: 'bg-red-100',
  },
  COMPLETED: {
    label: 'Completed',
    dotClass: 'bg-green-500',
    textClass: 'text-green-500',
    bgClass: 'bg-green-100',
  },
};

export const invoiceStatusColor = {
  NEW: {
    label: 'New',
    dotClass: 'bg-amber-500',
    textClass: 'text-amber-500',
  },
  PAYED: {
    label: 'Paid',
    dotClass: 'bg-green-500',
    textClass: 'text-green-500',
  },
};
