import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  apiClearShoppingCart,
  apiCreateOrder,
  apiGetShoppingCart,
  apiStoreToShoppingCart,
} from 'services/UserApiService';

export const getShoppingCart = createAsyncThunk(
  'cart/getShoppingCart',
  async data => {
    const resp = await apiGetShoppingCart(data);
    return resp.data;
  }
);

export const storeToShoppingCart = async data => {
  const resp = await apiStoreToShoppingCart(data);
  return resp.data;
};

export const clearShoppingCart = createAsyncThunk(
  'cart/clearShoppingCart',
  async data => {
    const resp = await apiClearShoppingCart(data);
    return resp.data;
  }
);

export const createOrderFromCart = async data => {
  const resp = await apiCreateOrder(data);
  return resp.data;
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {
    setShoppingCartData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getShoppingCart.pending, state => {
        state.loading = true;
      })
      .addCase(getShoppingCart.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(clearShoppingCart.pending, state => {
        state.loading = true;
      })
      .addCase(clearShoppingCart.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      });
  },
});

export const { setShoppingCartData } = cartSlice.actions;

export default cartSlice.reducer;
