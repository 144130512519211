import { PERSIST_STORE_NAME } from "constants/app.constant";
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { apiCheckAuth } from "services/AuthService";

const middlewares = [];

const persistConfig = {
    key: PERSIST_STORE_NAME,
    keyPrefix: "",
    storage,
    whitelist: ["auth"],
};

const checkAuthFunc = (dispatch, getState) => {
    // Make an async HTTP request
    const { token, signedIn } = getState().auth.session;
    if (token && signedIn) {
        apiCheckAuth()
            .then((resp) => {})
            .catch((err) => {});
    }
};

const checkAuthMiddleware = (storeAPI) => (next) => (action) => {
    if (action.type === "base/common/setCurrentRouteKey") {
        storeAPI.dispatch(checkAuthFunc);
    }
    return next(action);
};
middlewares.push(checkAuthMiddleware);

const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer()),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(middlewares),
    devTools: process.env.NODE_ENV === "development",
});

store.asyncReducers = {};

export const persistor = persistStore(store);

export const injectReducer = (key, reducer) => {
    if (store.asyncReducers[key]) {
        return false;
    }
    store.asyncReducers[key] = reducer;
    store.replaceReducer(
        persistReducer(persistConfig, rootReducer(store.asyncReducers))
    );
    persistor.persist();
    return store;
};

export default store;
